<template>

  <v-dialog v-model="show" max-width="550px" scrollable persistent>

    <v-card>
      <v-progress-linear
          :active="loading"
          color="primary"
          indeterminate
          absolute
          bottom
      ></v-progress-linear>

      <v-card-title class="text-h6 primary ztitle">

        <slot name="title-left"/>

        <v-spacer></v-spacer>

        <slot name="title-right"/>

        <v-btn class="mx-0 ztitle" icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>

      </v-card-title>

      <v-card-text>
        <v-container>
          <slot/>
        </v-container>
      </v-card-text>

      <v-card-actions class="justify-end innershadow">
        <slot name="actions" :close="dialog_close"/>
        <v-btn text @click="dialog_close()">
          Отмена
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>

export default {
  props: {
    value: Boolean,
    loading: {type: Boolean, default: false}
  },
  data: () => ({}),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
        this.$emit('close', value)
      }
    }
  },
  methods: {
    dialog_close() {
      this.show = false
    }
  },
}
</script>

<style scoped>

.innershadow {
  box-shadow: 0px -4px 12px 0px rgb(224 224 224) inset;
}

.ztitle {
  color: white!important;
  /*text-overflow: ellipsis;*/
  /*max-width: 550px;*/
  /*overflow: hidden;*/
  /*white-space: nowrap;*/
}

</style>